import { useEditor } from "@craftjs/core";
import { useComponentUsage } from "@modules/components/hooks/useComponentUsage";
import { Icon } from "@ui/Icon";
import {
  PlanManager,
  IncludedFeatures,
  MeteredFeatures,
  UpcomingBill,
  PaymentMethod,
  Invoices,
} from "../../../elements";

export const BuilderSidebarSeparator = () => {
  return <div className="h-px bg-gray-300 -mx-8 w-[calc(100%_+4rem)]"></div>;
};

interface ComponentListItemProps {
  condition: boolean;
  component: React.ReactElement;
  icon: string;
  name: React.ReactNode;
}

const ComponentListItem = ({
  condition,
  component,
  icon,
  name,
}: ComponentListItemProps) => {
  const { connectors } = useEditor();

  return (
    <>
      {!condition && (
        <div
          ref={(ref) => connectors.create(ref!, component)}
          className="min-h-[100px] justify-center bg-blue-50 cursor-pointer rounded text-sm text-center flex flex-col items-center p-4 space-y-2"
        >
          <span className="text-2xl leading-none">{icon}</span>
          <span className="leading-none text-xs text-gray-400">{name}</span>
        </div>
      )}

      {condition && (
        <div className="cursor-not-allowed justify-center min-h-[100px] group relative bg-blue-50/50 rounded text-sm text-center flex flex-col items-center p-4 space-y-2">
          <svg
            className="absolute top-2 right-2 opacity-50"
            viewBox="0 0 16 16"
            width={16}
            height={16}
          >
            <path
              d="M8.00011 15.3332C9.94507 15.3332 11.81 14.5607 13.1857 13.1857C14.5607 11.8101 15.3332 9.94507 15.3332 8.00011C15.3332 6.05515 14.5607 4.19019 13.1857 2.81451C11.8101 1.4395 9.94507 0.666992 8.00011 0.666992C6.05515 0.666992 4.19019 1.43949 2.81451 2.81451C1.4395 4.19014 0.666992 6.05515 0.666992 8.00011C0.666992 9.94507 1.43949 11.81 2.81451 13.1857C4.19014 14.5607 6.05515 15.3332 8.00011 15.3332ZM4.19323 8.19323C4.45323 7.93511 4.87323 7.93511 5.13323 8.19323L6.66698 9.72698L10.527 5.86698C10.7913 5.6401 11.1857 5.65573 11.432 5.90198C11.6782 6.14823 11.6932 6.54198 11.467 6.80697L7.13385 11.1401C6.87385 11.3988 6.45322 11.3988 6.19322 11.1401L4.19322 9.14009C4.06697 9.01509 3.99634 8.84446 3.99634 8.66697C3.99634 8.48884 4.06698 8.31885 4.19323 8.19323Z"
              fill="#194BFB"
            />
          </svg>
          <span className="text-2xl leading-none opacity-50">{icon}</span>
          <span className="leading-none text-xs text-gray-400 opacity-50">
            {name}
          </span>
          <div className="absolute inset-0 bg-white bg-opacity-50"></div>

          <div className="group-hover:opacity-100  leading-4 group-hover:visible invisible opacity-0 absolute bg-white shadow-md p-2 text-xs w-[140px] rounded-md bottom-[calc(100%+10px)]">
            This component can
            <br />
            only be used once
          </div>
        </div>
      )}
    </>
  );
};

export const ComponentsList = () => {
  const isPlanManagerComponentUsed = useComponentUsage("PlanManager");
  const isIncludedFeaturesComponentUsed = useComponentUsage("IncludedFeatures");
  const isMeteredFeaturesComponentUsed = useComponentUsage("MeteredFeatures");
  const isUpcomingBillComponentUsed = useComponentUsage("UpcomingBill");
  const isPaymentMethodComponentUsed = useComponentUsage("PaymentMethod");
  const isInvoicesComponentUsed = useComponentUsage("Invoices");

  const planComponentsListItems = [
    {
      condition: isPlanManagerComponentUsed,
      component: <PlanManager />,
      icon: "📦",
      name: (
        <>
          Current
          <br />
          Plan
        </>
      ),
    },
    {
      condition: isIncludedFeaturesComponentUsed,
      component: <IncludedFeatures />,
      icon: "☑️",
      name: (
        <>
          Included
          <br />
          Features
        </>
      ),
    },
    {
      condition: isMeteredFeaturesComponentUsed,
      component: <MeteredFeatures />,
      icon: "🎛️",
      name: (
        <>
          Metered
          <br />
          Features
        </>
      ),
    },
  ];

  const billingComponentsListItems = [
    {
      condition: isUpcomingBillComponentUsed,
      component: <UpcomingBill />,
      icon: "🧾",
      name: "Next Bill Due",
    },
    {
      condition: isPaymentMethodComponentUsed,
      component: <PaymentMethod />,
      icon: "💳",
      name: (
        <>
          Payment
          <br />
          Methods
        </>
      ),
    },
    {
      condition: isInvoicesComponentUsed,
      component: <Invoices />,
      icon: "📄",
      name: "Invoices",
    },
  ];

  return (
    <div className="p-8">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <Icon
              name="chevron-down"
              className="leading-none text-base text-gray-400"
            />
            <span className="text-sm leading-none">Entitlements</span>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {planComponentsListItems.map((i, index) => {
              return (
                <ComponentListItem
                  icon={i.icon}
                  name={i.name}
                  condition={i.condition}
                  component={i.component}
                  key={index}
                />
              );
            })}
          </div>
        </div>

        <BuilderSidebarSeparator />

        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <Icon
              name="chevron-down"
              className="leading-none text-base text-gray-400"
            />
            <span className="text-sm leading-none">Billing</span>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {billingComponentsListItems.map((i, index) => {
              return (
                <ComponentListItem
                  icon={i.icon}
                  name={i.name}
                  condition={i.condition}
                  component={i.component}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
